/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Nav Styles */
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white);
    background: transparent; /* Make nav background transparent to blend into the hero */
    z-index: 1;
    
  }
  
  .logo h1 {
    font-size: 24px;
    color: var(--white);
    
  }
  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    
  }
  
  .nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: var(--white);
    font-size: 18px;
  }
  
  .cta-button {
    background-color: var(--cta-color);
    color: var(--white);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: var(--shamrock-green);
  }
  
  