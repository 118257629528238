/* SectionOne.css */
.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  margin: 0 auto;
  background-color: var(--light-gray);
  box-sizing: border-box;
  overflow: hidden;
}

.header-container {
  margin-bottom: 20px;
}

/* Centering the slider items */
.slider {
  width: 100%;
  display: flex;
  justify-content: center;
}

.slider-item {
  display: flex;
  justify-content: center; /* Center the image inside the slider */
  
}

.section-image {
  width: 100%; /* Make image responsive */
  max-width: 500px; /* Limit the maximum size of the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: rounded corners */
}
.alt{
  background-color: white;
}

/* Custom Arrows */
.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
}


/* Media Queries for Desktop */
@media (min-width: 768px) {
  .section-container {
    padding: 40px;         /* Add more padding for desktop */
    display: flex;
    justify-content: center;
  }
  
  .header-container {
    width: 40%;
  }

  .slider {
    max-width: 300px; /* Control the width of the slider */
    width: 100%; /* Make it responsive */
  }
  
  /* Centering the slider items */
  .slider-item {
    display: flex; /* Use flex to center the image */
    justify-content: center; /* Center the image inside */
  }
  
  /* Image styles */
  .section-image {
    width: 100%; /* Make image responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: rounded corners */
  }
  
  /* Custom Arrow Styles */
  .arrow {
    font-size: 24px; /* Size of the arrow */
    color: white; /* Arrow color */
  }
  
  .slick-prev {
    left: -40px; /* Position the left arrow */
  }
  
  .slick-next {
    right: -40px; /* Position the right arrow */
  }
}
