:root {
    --gunmetal: #2a2d34ff;
    --celestial-blue: #009ddcff;
    --giants-orange: #f26430ff;
    --ultra-violet: #6761a8ff;
    --shamrock-green: #009b72ff;
    --white: #ffffff;
    --cta-color: #f76e11ff; /* New CTA color suggestion */
    --light-gray: #f0f0f0; /* Soft light gray */
  }
  