/* Inquiry Form Styles */
.inquiry-form {
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
    max-width: 400px; /* Set a max width for the form */
    margin: 0 auto; /* Center the form horizontally */
    padding: 20px; /* Add padding around the form */
    background-color: var(--white); /* Background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .input-field {
    margin-bottom: 15px; /* Space between inputs */
    padding: 10px; /* Padding for input fields */
    border: 1px solid var(--celestial-blue); /* Border color */
    border-radius: 5px; /* Rounded corners */
    font-size: 1rem; /* Font size for input text */
    transition: border-color 0.3s; /* Transition for border color on focus */
  }
  
  .input-field:focus {
    border-color: var(--giants-orange); /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .submit-button {
    background-color: var(--giants-orange); /* Button background color */
    color: var(--white); /* Button text color */
    padding: 10px; /* Padding for the button */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.1rem; /* Font size for button text */
    transition: background-color 0.3s; /* Transition for background color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Horizontal | Vertical | Blur | Color */
  }
  
  .submit-button:hover {
    background-color: var(--shamrock-green); /* Change background color on hover */
  }
  
  /* Thank You Message Styles */
  .thank-you-message {
    text-align: center; /* Center the thank-you message */
    margin-top: 20px; /* Space above the message */
    color: var(--font-dark); /* Adjust text color */
  }
  
  .thank-you-message h2 {
    font-size: 2rem; /* Size for the thank-you heading */
  }
  
  .thank-you-message p {
    font-size: 1rem; /* Size for the thank-you paragraph */
  }
  