/* Hero.css */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 20px; /* Reduced padding for mobile */
  background: linear-gradient(180deg, var(--ultra-violet), var(--celestial-blue));
  color: var(--white);
}
a{
  text-decoration: none;
}

.hero-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  
  
}

.hero-text {
  flex: 1;
  padding-right: 20px; /* Reduced padding */
}

.hero-mockup {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the image vertically */
}

.mockup-svg {
  width: 100%; /* Full width for responsive design */
  max-width: 300px; /* Limit the size */
  height: auto; /* Maintain aspect ratio */
}

/* Header and paragraph styles */
h1 {
  font-size: 2.5rem; /* Responsive header size */
  margin-bottom: 20px;
}

p {
  font-size: 1.25rem; /* Responsive paragraph size */
  margin-bottom: 30px;
}

/* Submit Button */
.inquire-button {
  background-color: var(--shamrock-green);
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 0.5rem 1.5rem; /* Padding for button */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 1rem; /* Font size */
  transition: background-color 0.3s; /* Transition for hover effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Horizontal | Vertical | Blur | Color */
  font-weight: 600;
  
}

.inquire-button:hover {
  background-color: darkorange; /* Change color on hover */
}


/* Media Queries for Mobile */
@media (max-width: 768px) {
  .hero-content {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
  }

  .hero-text {
    padding-top: 20px;
    padding-right: 0; /* Remove right padding on mobile */
    text-align: center; /* Center text */
    width: 100%; /* Full width */
  }

  .hero-mockup {
    margin-top: 20px; /* Space above the mockup */
    width: 100%; /* Full width on mobile */
  }

  h1 {
    font-size: 2rem; /* Responsive header size */
  }

  p {
    font-size: 1rem; /* Responsive paragraph size */
  }
}
